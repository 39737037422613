<template>
  <div
    id="modal"
    ref="modal"
    class="modal_mask"
    :class="mainClass"
    @click="minimizeOutsideClick ? minimize() : onClose()">
    <transition
      :name="transitionClass"
      @after-leave="finishTransition">
      <div
        v-show="modalMounted && showModal"
        class="modal-wrapper"
        :style="styles"
        @click.stop
        @touchmove="cancelEvent"
        @touchstart="cancelEvent"
        @touchend="cancelEvent">
        <div
          class="modal-container"
          :class="[{
                     'modal-container--header-layer': showHeaderLayer,
                     'modal-container--header-space': spanHeaderLayer },
                   containerClass ]">
          <div v-if="showMinimizeButton">
            <button
              id="modal-minimize"
              :aria-label="$t('global.modal.minimize', { title: title })"
              :title="$t('global.modal.minimize', { title: title })"
              @click="minimize">
              <span class="icon minus"></span>
            </button>
          </div>
          <button
            v-if="showCloseButton"
            id="modal-close"
            :aria-label="$t('global.modal.close', { title: title })"
            :title="$t('global.modal.close', { title: title })"
            @click="onClose">
            <span class="icon cross"></span>
          </button>
          <template v-if="isCustomModal">
            <slot name="body"></slot>
          </template>
          <template v-else>
            <div
              v-if="showHeaderSlot"
              class="modal-header">
              <slot name="header"></slot>
            </div>
            <div class="modal-body">
              <slot name="body"></slot>
            </div>
            <div
              v-if="showFooterSlot"
              class="modal-footer">
              <slot name="footer"></slot>
            </div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import MODAL_TYPES from 'Utils/modalTypes';

  export default {
    name: 'Modal',
    props: {
      showCloseButton: {
        type: Boolean,
        default: () => {
          return true;
        },
      },
      showMinimizeButton: {
        type: Boolean,
        default: false,
      },
      transitionClass: {
        type: String,
        default: 'modal-content-transition',
      },
      closedTransition: {
        type: String,
        default: 'closed',
      },
      mainClass: {
        type: [String, Object],
        default: MODAL_TYPES.FULL_SCREEN,
      },
      containerClass: {
        type: [String, Object],
        default: '',
      },
      styles: {
        type: Object,
        default: () => {
          return {};
        },
      },
      showModal: {
        type: Boolean,
        default: true,
      },
      title: {
        type: String,
        default: 'modal',
      },
      isCustomModal: {
        type: Boolean,
        default: false,
      },
      forceCloseModal: {
        type: Boolean,
        default: false,
      },
      applyTouchCancel: {
        type: Boolean,
        default: true,
      },
      confirmBeforeClose: {
        type: Boolean,
        default: false,
      },
      preventOutsideClickClose: {
        type: Boolean,
        default: false,
      },
      minimizeOutsideClick: {
        type: Boolean,
        default: false,
      },
      unlockOverflowOnUnmount: {
        type: Boolean,
        default: true,
      },
      showHeaderLayer: {
        type: Boolean,
        default: true
      },
      spanHeaderLayer: {
        type: Boolean,
        default: false
      }
    },
    emits: ['close','confirmBeforeClose', 'minimizeModal'],
    data() {
      return {
        modalMounted: false,
      }
    },
    computed: {
      showFooterSlot() {
        return !!this.$slots.footer;
      },
      showHeaderSlot() {
        return !!this.$slots.header;
      },
    },
    watch: {
      forceCloseModal: function (newValue) {
        if (newValue) {
          this.onClose();
        }
      },
    },
    mounted() {
      if (this.$refs.modal.style.display !== 'none') document.body.style.overflow = 'hidden';
      this.modalMounted = true;
    },
    updated() {
      if (document.body.style.overflow === 'hidden') return;
      if (this.$refs.modal.style.display === 'none') return;
      document.body.style.overflow = 'hidden';
    },
    beforeUnmount() {
      if (this.unlockOverflowOnUnmount) {
        document.body.style.overflow = 'auto';
      }
    },
    methods: {
      onClose() {
        if (this.preventOutsideClickClose) return;

        if (this.confirmBeforeClose) {
          this.$emit('confirmBeforeClose');
          return;
        } 
        this.closedAnimation();
        this.modalMounted = false;
      },
      closedAnimation() {
        this.$refs.modal.classList.add(this.closedTransition);
      },
      finishTransition() {
        this.$emit('close');
      },
      cancelEvent(e) {
        if (!this.applyTouchCancel) return;
        e.stopPropagation();
      },
      minimize() {
        this.$emit('minimizeModal');
      }
    },
  };
</script>
